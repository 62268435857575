import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Image,
  Modal,
  Row,
  Col,
  Tab,
  Nav,
  InputGroup,
} from "react-bootstrap";
import CenterLoader from "../helper/CommonCenterLoader";
import { getKeyStart } from "../../store/actions/UserAction";
import { connect } from "react-redux";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../helper/ToastNotification";

const KeyModal = (props) => {
  const [step, setStep] = useState(1);
  const [loginPasswordVisible, setLoginPasswordVisible] = useState(false);
  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("password required"),
  });

  const handleSubmit = (values) => {
    props.dispatch(
      getKeyStart({
        ...values,
        type: props.keyModal,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.keyData.loading &&
      Object.keys(props.keyData.data).length > 0
    ) {
      setStep(2);
    }
    setSkipRender(false);
  }, [props.keyData]);

  return (
    <>
      <Modal
        className="modal-dialog-center kyc-update-modal"
        size="md"
        centered
        show={props.keyModal}
        onHide={props.closeKeyModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Get {props.keyModal === 1 ? "Test" : "Production"} Key
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {step === 1 ? (
              <Formik
                initialValues={{
                  password: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => handleSubmit(values)}
              >
                {({ touched, errors }) => (
                  <FORM className="auth-form-sec">
                    <div className="auth-form-box">
                      <Form.Group controlId="formBasicEmail" style={{margin:"10px 0px"}}>
                        <Form.Label>
                          Password <span>*</span>
                        </Form.Label>
                        <InputGroup>
                          <Field
                            type={loginPasswordVisible ? "text" : "password"}
                            name="password"
                            placeholder="password"
                            className="form-control"
                          />
                          <InputGroup.Text
                            id="basic-addon2"
                            onClick={() =>
                              setLoginPasswordVisible(!loginPasswordVisible)
                            }
                          >
                            {loginPasswordVisible ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <g clipPath="url(#clip0_1_56)">
                                  <path
                                    fill="#3C3C41"
                                    d="M12 6.5c2.76 0 5 2.24 5 5 0 .51-.1 1-.24 1.46l3.06 3.06c1.39-1.23 2.49-2.77 3.18-4.53C21.27 7.11 17 4 12 4c-1.27 0-2.49.2-3.64.57l2.17 2.17c.47-.14.96-.24 1.47-.24zM2.71 3.16a.996.996 0 000 1.41l1.97 1.97A11.892 11.892 0 001 11.5C2.73 15.89 7 19 12 19c1.52 0 2.97-.3 4.31-.82l2.72 2.72a.996.996 0 101.41-1.41L4.13 3.16c-.39-.39-1.03-.39-1.42 0zM12 16.5c-2.76 0-5-2.24-5-5 0-.77.18-1.5.49-2.14l1.57 1.57c-.03.18-.06.37-.06.57 0 1.66 1.34 3 3 3 .2 0 .38-.03.57-.07L14.14 16c-.65.32-1.37.5-2.14.5zm2.97-5.33a2.97 2.97 0 00-2.64-2.64l2.64 2.64z"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0_1_56">
                                    <path fill="#fff" d="M0 0H24V24H0z"></path>
                                  </clipPath>
                                </defs>
                              </svg>
                            ) : (
                              <svg
                                width="19"
                                height="16"
                                viewBox="0 0 19 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.5 1.5C5.86985 1.5 2.57784 3.77968 0.148665 7.4825C-0.0495551 7.78586 -0.0495551 8.20968 0.148665 8.51304C2.57784 12.2203 5.86985 14.5 9.5 14.5C13.1302 14.5 16.4222 12.2203 18.8513 8.5175C19.0496 8.21414 19.0496 7.79032 18.8513 7.48696C16.4222 3.77968 13.1302 1.5 9.5 1.5ZM9.76041 12.5772C7.35067 12.7512 5.36069 10.4715 5.51227 7.7011C5.63665 5.41695 7.24962 3.56555 9.23959 3.42279C11.6493 3.2488 13.6393 5.52848 13.4877 8.2989C13.3595 10.5786 11.7465 12.43 9.76041 12.5772ZM9.63992 10.4626C8.34177 10.5563 7.26905 9.32944 7.35456 7.8394C7.42063 6.6081 8.29124 5.61325 9.36397 5.53294C10.6621 5.43926 11.7348 6.6661 11.6493 8.15614C11.5794 9.3919 10.7088 10.3868 9.63992 10.4626Z"
                                  fill="black"
                                />
                              </svg>
                            )}
                          </InputGroup.Text>
                        </InputGroup>
                        <ErrorMessage
                          component={"div"}
                          name="password"
                          className="text-danger text-right"
                        />
                      </Form.Group>
                      <div className="auth-btn-sec" style={{display:"flex", justifyContent:"center", marginTop:"20px"}}>
                        <Button
                          className="default-btn"
                          type="submit"
                          disabled={props.keyData.buttonDisable}
                        >
                          {props.keyData.buttonDisable ? "Loading" : "Submit"}
                        </Button>
                      </div>
                    </div>
                  </FORM>
                )}
              </Formik>
            ) : null}
            {step === 2 && (
              <div className="api-key-success-sec" style={{padding:"10px"}}>
                <div style={{ display: "flex", flexDirection:"column", gap: "1em", alignItems:"center" }}>
                  <h5>API Key</h5>
                  <p style={{ margin:"0", wordBreak:"break-all", background:"#F1F1F1", padding:"0.5em" }}>{props.keyData.data.api_key}</p>
                  <CopyToClipboard
                    text={props.keyData.data.api_key}
                    onCopy={(e) => getSuccessNotificationMessage("API Key copied successfully")}>
                    <Image
                      src={window.location.origin + "/img/sell/copy-icon.svg"}
                      className="swap-connect-icon"
                    />
                  </CopyToClipboard>
                </div>
                <div className="auth-btn-sec">
                  <Button
                    className="default-btn w-100"
                    type="submit"
                    onClick={() => props.closeKeyModal()}
                  >
                    Done
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  kycApplicant: state.user.kycApplicant,
  keyData: state.user.keyData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(mapStateToPros, mapDispatchToProps)(KeyModal);
