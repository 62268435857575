import {
  BUY_TOKEN_FAILURE,
  BUY_TOKEN_START,
  BUY_TOKEN_SUCCESS,
  CREATE_KYC_APPLICANT_FAILURE,
  CREATE_KYC_APPLICANT_START,
  CREATE_KYC_APPLICANT_SUCCESS,
  GENERATE_STRIPE_PAYMENT_FAILURE,
  GENERATE_STRIPE_PAYMENT_START,
  GENERATE_STRIPE_PAYMENT_SUCCESS,
  SWAP_TOKEN_FAILURE,
  SWAP_TOKEN_START,
  SWAP_TOKEN_SUCCESS,
  SET_BUY_DATA,
  SET_SWAP_DATA,
  RESET_BUY_DATA,
  RESET_SWAP_DATA,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER_START,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  GET_LOGIN_DETAILS,
  PROFILE_START,
  PROFILE_SUCCESS,
  PROFILE_FAILURE,
  UPDATE_KYC_START,
  UPDATE_KYC_SUCCESS,
  UPDATE_KYC_FAILURE,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_START,
  UPDATE_PROFILE_SUCCESS,
  STYLOPAY_PAYMENT_FAILURE,
  STYLOPAY_PAYMENT_START,
  STYLOPAY_PAYMENT_SUCCESS,
  TRANSATIONS_LIST_FAILURE,
  TRANSATIONS_LIST_START,
  TRANSATIONS_LIST_SUCCESS,
  FETCH_MORE_TRANSATIONS,
  BANK_PAYMENT_FAILURE,
  BANK_PAYMENT_START,
  BANK_PAYMENT_SUCCESS,
  RESET_BANK_DATA,
  GET_KEY_START,
  GET_KEY_SUCCESS,
  GET_KEY_FAILURE,
} from "./ActionConstant";

// Store Data
export const updateBuyData = data => ({ type: SET_BUY_DATA, data });
export const resetBuyData = data => ({ type: RESET_BUY_DATA, data });
export const updateSwapData = data => ({ type: SET_SWAP_DATA, data });
export const resetSwapData = data => ({ type: RESET_SWAP_DATA, data });
export const resetBankData = data => ({ type: RESET_BANK_DATA, data });


// Generate stripe
export const generateStripePaymentStart = data => ({ type: GENERATE_STRIPE_PAYMENT_START, data })
export const generateStripePaymentSuccess = data => ({ type: GENERATE_STRIPE_PAYMENT_SUCCESS, data })
export const generateStripePaymentFailure = error => ({ type: GENERATE_STRIPE_PAYMENT_FAILURE, error })

// Buy Token
export const buyTokenStart = data => ({ type: BUY_TOKEN_START, data })
export const buyTokenSuccess = data => ({ type: BUY_TOKEN_SUCCESS, data })
export const buyTokenFailure = error => ({ type: BUY_TOKEN_FAILURE, error })

// Create kyc applicant
export const createKycApplicantStart = data => ({ type: CREATE_KYC_APPLICANT_START, data })
export const createKycApplicantSuccess = data => ({ type: CREATE_KYC_APPLICANT_SUCCESS, data })
export const createKycApplicantFailure = error => ({ type: CREATE_KYC_APPLICANT_FAILURE, error })

// Swap token
export const swapTokenStart = data => ({ type: SWAP_TOKEN_START, data })
export const swapTokenSuccess = data => ({ type: SWAP_TOKEN_SUCCESS, data })
export const swapTokenFailure = error => ({ type: SWAP_TOKEN_FAILURE, error })

export const transactionsStart = data => ({ type: TRANSATIONS_LIST_START, data })
export const fetchMoreTransactionsStart = data => ({ type: FETCH_MORE_TRANSATIONS, data })
export const transactionsSuccess = data => ({ type: TRANSATIONS_LIST_SUCCESS, data })
export const transactionsFailure = error => ({ type: TRANSATIONS_LIST_FAILURE, error })

// Auth Actions
export const loginStart = data => ({ type: LOGIN_START, data })
export const loginSuccess = data => ({ type: LOGIN_SUCCESS, data })
export const loginFailure = error => ({ type: LOGIN_FAILURE, error })

export const registerStart = data => ({ type: REGISTER_START, data })
export const registerSuccess = data => ({ type: REGISTER_SUCCESS, data })
export const registerFailure = error => ({ type: REGISTER_FAILURE, error })

// Profile Actions
export const profileStart = data => ({ type: PROFILE_START, data })
export const profileSuccess = data => ({ type: PROFILE_SUCCESS, data })
export const profileFailure = error => ({ type: PROFILE_FAILURE, error })

export const profileUpdateStart = data => ({ type: UPDATE_PROFILE_START, data })
export const profileUpdateSuccess = data => ({ type: UPDATE_PROFILE_SUCCESS, data })
export const profileUpdateFailure = error => ({ type: UPDATE_PROFILE_FAILURE, error })

export const updateKYCStart = data => ({ type: UPDATE_KYC_START, data })
export const updateKYCSuccess = data => ({ type: UPDATE_KYC_SUCCESS, data })
export const updateKYCFailure = error => ({ type: UPDATE_KYC_FAILURE, error })


// Create STYLOPAY
export const stylopayPaymentStart = data => ({ type: STYLOPAY_PAYMENT_START, data })
export const stylopayPaymentSuccess = data => ({ type: STYLOPAY_PAYMENT_SUCCESS, data })
export const stylopayPaymentFailure = error => ({ type: STYLOPAY_PAYMENT_FAILURE, error })

// Create bank payment
export const bankPaymentStart = data => ({ type: BANK_PAYMENT_START, data })
export const bankPaymentSuccess = data => ({ type: BANK_PAYMENT_SUCCESS, data })
export const bankPaymentFailure = error => ({ type: BANK_PAYMENT_FAILURE, error })

// Key
export const getKeyStart = data => ({ type: GET_KEY_START, data })
export const getKeySuccess = data => ({ type: GET_KEY_SUCCESS, data })
export const getKeyFailure = error => ({ type: GET_KEY_FAILURE, error })