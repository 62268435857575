import React, { useState, useEffect } from "react";
import {
  Image,
  Form,
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Button,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  transactionsStart,
  fetchMoreTransactionsStart,
} from "../../store/actions/UserAction";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../../components/helper/ToastNotification";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../helper/NoDataFound";

const TransactionsIndex = (props) => {
  const dispatch = useDispatch();
  const transactions = useSelector((state) => state.user.transactions);

  useEffect(() => {
    dispatch(
      transactionsStart({
        skip: 0,
        take: 12,
      })
    );
  }, []);

  const fetchMoreOffers = () => {
    dispatch(
      fetchMoreTransactionsStart({
        skip: transactions.data.transactions.length,
        take: 12,
      })
    );
  };

  const onCopy = (event, type) => {
    getSuccessNotificationMessage(`${type} was copied to clipboard!`);
  };

  const getStatus = (status) => {
    switch (status) {
      case 0:
        return "transaction-initiated";
      case 1:
        return "transaction-success";
      case 2:
        return "transaction-failed";
      default:
        return "transaction-initiated";
    }
  };

  return (
    <>
      <div className="login-sec">
        <div className="login-box-1">
          <Container>
            <Row className="justify-content-md-center">
              <Col md={12}>
                {transactions.loading ? (
                  <div className="exchange-box">
                    <div className="exchange-card exchange-history-list-card">
                      <Skeleton count={5} height={100} />
                    </div>
                  </div>
                ) : Object.keys(transactions.data).length > 0 &&
                  transactions.data.transactions.length > 0 ? (
                  <div className="exchange-box">
                    <div className="exchange-card exchange-history-list-card">
                      <InfiniteScroll
                        dataLength={transactions.data.transactions.length}
                        next={fetchMoreOffers}
                        hasMore={
                          transactions.data.transactions.length <
                          transactions.data.total_transactions
                        }
                        loader={
                          <div className="d-flex justify-content-center mb-5">
                            {/* <CenterLoader /> */}
                          </div>
                        }
                      >
                        <Table borderedless responsive>
                          <thead>
                            <tr>
                              <th>Payment Id</th>
                              <th>Amount</th>
                              <th>Payment Type</th>
                              <th>Payment Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transactions.data.transactions.map(
                              (transaction, i) => (
                                <tr key={i}>
                                  <td>
                                    <div className="exchange-amount-sec">
                                      <h5>#{transaction.unique_id}</h5>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="exchange-amount-sec">
                                      <h5>
                                        {transaction.amount}
                                      </h5>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="exchange-amount-sec">
                                      <h5>{transaction.formatted_payment_type}</h5>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="exchange-amount-sec">
                                      <h5>{transaction.payment_status_formatted}</h5>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="exchange-amount-sec">
                                      {transaction.picture ? <a className="default-btn" style={{width:"max-content"}} href={transaction.picture} target="_blank" rel="noreferrer">
                                        View Screenshot
                                      </a> : "-"}
                                    </div>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </InfiniteScroll>
                    </div>
                  </div>
                ) : (
                  <div className="exchange-box">
                    <div className="exchange-card exchange-history-list-card">
                      <NoDataFound />
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default TransactionsIndex;
